<template>
    <div class="w-full text-start sm:text-center lg:text-start" data-aos="zoom-in">
        <p
            class="inline-flex items-center justify-center 
            px-6 py-4 text-sm font-bold tracking-wide dark:text-white 
            rounded-t-full rtl:rounded-bl-full ltr:rounded-br-full bg-gradient-to-r
            dark:from-gray-600 dark:to-dark-700 from-primary-color-600
            to-secondary-color text-white">
            {{ msg }}
        </p>
    </div>
</template>
<script>
export default {
    props: {
        msg: {
            type: String,
            required: true
        }
    }
}
</script>