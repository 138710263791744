<template>
    <section class="max-w-screen-xl px-4 py-12 mx-auto md:py-16 sm:px-6 lg:px-8">
        <div class="relative py-16 rounded-3xl dark:bg-gray-800 bg-gray-200 lg:py-20">
            <svg class="absolute inset-y-0 top-0 z-20 w-1/4 h-full right-1/4 dark:text-gray-800 text-gray-200" preserveaspectratio="none"
                viewbox="0 0 100 50" fill="currentcolor">
                <polygon points="0,0 100,0 0,400" />
            </svg>
            <div class="absolute inset-y-0 z-10 w-1/2 h-full left-1/2 dark:bg-dark-800 bg-gray-300 rounded-r-3xl"></div>
            <div
                class="relative z-30 flex flex-col items-center justify-center px-4 mx-auto text-center sm:px-16 lg:flex-row lg:text-left">
                <div class="max-w-lg text-2xl font-bold sm:text-4xl lg:w-1/2">
                    <h5 class="text-start text-4xl font-extrabold tracking-tight dark:text-white text-secondary-color sm:text-5xl">
                        {{ $t('Lets make something great together.') }}
                    </h5>
                </div>
                <div class="flex justify-center max-w-lg mt-10 lg:w-1/2 lg:mt-0 lg:justify-end">
                    <router-link to="/joinus" class="flex items-center justify-center w-auto px-8 py-4 text-base font-semibold leading-snug transition ease-in-out bg-white rounded-full h-14 duration-250 text-dark-900 hover:text-white focus:outline-none hover:bg-primary-color-800 dark:hover:bg-primary-color-600"
                        >
                        {{ $t('Lets connect') }}
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>