import { createApp } from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";
import './registerServiceWorker'
import router from './router'
import { createI18n } from 'vue-i18n'
import '@/axios'
import '@/assets/css/main.css'
import '@/assets/css/animations.css'
import '@/assets/css/theme.css'
import AR from '@/locale/ar.json'
import EN from '@/locale/en.json'
import EN_CONTENT from '@/locale/en_content.json'
import AR_CONTENT from '@/locale/ar_content.json'
import { createPinia } from 'pinia'
import 'flowbite/dist/flowbite'
import 'aos/dist/aos'
import 'aos/dist/aos.css'
import vueCountryRegionSelect from 'vue3-country-region-select'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


const app = createApp(App)
app.use(createPinia())

import { useLocaleStore } from '@/store/index'
const store = useLocaleStore()
export const i18n = createI18n({
    globalInjection: true,
    legacy: false,
    locale : store.lang || "AR",
    messages:{
        EN: EN,
        AR: AR,
    }
})

app.use(vueCountryRegionSelect)
app.use(Toast,{})
app.use(i18n)
app.use(router)
app.use(VueGtag, {
    appName:'Fazaa',
    pageTrackerScreenviewEnabled:true,
    config: { id: "G-QPBHENTNK8" },
})
app.mount('#app')
