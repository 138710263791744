<template>
    <div class="contact">
        <section  class="px-4 py-4 md:py-6 sm:px-6 lg:px-8">
            <div class="max-w-screen-xl mx-auto">
                <div class="grid lg:grid-cols-2 lg:gap-x-8 xl:gap-x-16">
                    <div class="flex flex-col items-start justify-between sm:items-center lg:items-start">
                        <div>
                            <SectionTitle :msg="$t('contact us')" />
                            <div class="w-full mt-4 text-start md:mt-5 sm:text-center lg:text-start">
                                <transition appear name="fade">
                                    <div>
                                        <h1 class="text-4xl font-extrabold text-white sm:text-5xl md:text-6xl">
                                            <span
                                                class="text-transparent bg-clip-text bg-gradient-to-r from-primary-color-600 to-secondary-color dark:from-primary-color-600 dark:to-white">
                                                {{ $t('get in touch') }}
                                            </span>
                                        </h1>
                                    </div>
                                </transition>
                                <p class="max-w-lg mx-auto mt-3 text-xl md:mt-5 text-gray-950 dark:text-white sm:max-w-2xl">
                                    {{ $t('get in touch paragraph') }}
                                </p>
                            </div>
                        </div>
                        <div data-aos="fade-up" class="grid gap-6 text-start mt-8 sm:mt-10 lg:mt-0 sm:grid-cols-2 sm:grid-rows-2 sm:gap-12">
                            <div>
                                <h5 class="text-lg text-start font-bold text-gray-950 dark:text-white">{{ $t('riyadh, saudi arabia') }}</h5>
                                <p class="mt-1 text-start text-gray-950 dark:text-white">
                                    {{ $t('6485, King Abdulaziz Road') }}
                                    <br />{{ $t('2702, Riyadh, Saudi Arabia') }}
                                </p>
                            </div>
                            <div>
                                <h5 class="text-lg text-start font-bold text-gray-950 dark:text-white">{{ $t('riyadh, saudi arabia') }}</h5>
                                <p class="mt-1 text-start text-gray-950 dark:text-white">
                                    {{ $t('6485, King Abdulaziz Road') }}
                                    <br />{{ $t('2702, Riyadh, Saudi Arabia') }}
                                </p>
                            </div>
                        </div>
                        <div data-aos="fade-up" class="w-full mt-8 sm:mt-10 lg:mt-0">
                            <h6 class="text-lg font-semibold  text-gray-950 dark:text-white sm:text-center lg:text-start">
                                {{ $t('follow us') }}
                            </h6>
                            <div dir="ltr" class="flex justify-center lg:justify-center mt-3 space-x-4 sm:justify-center ">
                                <a class="flex items-center justify-center w-12 h-12 transition duration-300 ease-in-out rounded-full dark:bg-white bg-secondary-color hover:bg-primary-color-600 dark:hover:bg-primary-color-600 text-white dark:text-secondary-color"
                                    href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" width="44" height="44"
                                        viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <rect x="4" y="4" width="16" height="16" rx="4" />
                                        <circle cx="12" cy="12" r="3" />
                                        <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
                                    </svg>
                                </a>
                                <a class="flex items-center justify-center w-12 h-12 transition duration-300 ease-in-out rounded-full dark:bg-white bg-secondary-color hover:bg-primary-color-600 dark:hover:bg-primary-color-600 text-white dark:text-secondary-color"
                                    href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" width="44" height="44"
                                        viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path
                                            d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                                    </svg>
                                </a>
                                <a class="flex items-center justify-center w-12 h-12 transition duration-300 ease-in-out rounded-full dark:bg-white bg-secondary-color hover:bg-primary-color-600 dark:hover:bg-primary-color-600 text-white dark:text-secondary-color"
                                    href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" width="44" height="44"
                                        viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path
                                            d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="w-full mt-12 lg:mt-0" data-aos="fade-up">
                        <div
                            class="w-full px-4 py-12 mx-auto shadow-xl rounded-3xl lg:mr-0 lg:ml-auto dark:bg-dark-800 bg-gray-200 sm:p-16 lg:p-14 xl:p-16">
                            <form>
                                <div>
                                    <label for="name" class="ml-0.5 dark:text-gray-300 font-bold text-sm text-start">
                                        {{ $t('name') }} *
                                    </label>
                                    <input id="name" type="text" name="name" placeholder="Ahmed Ali"
                                        class="w-full p-4 mt-2 text-sm font-bold text-white transition duration-200 ease-in-out border-2 border-solid outline-none h-14 rounded-2xl dark:bg-dark-700 bg-gray-200 dark:border-dark-800 border-gray-400  focus:border-primary-color-600 focus:outline-none"
                                        required />
                                </div>
                                <div class="mt-6">
                                    <label for="email" class="ml-0.5 dark:text-gray-300 font-bold text-sm">
                                        {{ $t('your email') }} *
                                    </label>
                                    <input id="email" type="email" name="email" placeholder="ahmed@email.com"
                                        class="w-full p-4 mt-2 text-sm font-bold text-white transition duration-200 ease-in-out border-2 border-solid outline-none h-14 rounded-2xl dark:bg-dark-700 bg-gray-200 dark:border-dark-800 border-gray-400  focus:border-primary-color-600 focus:outline-none"
                                        required />
                                </div>
                                <div class="mt-6">
                                    <label for="phone" class="ml-0.5 dark:text-gray-300 font-bold text-sm">
                                        {{ $t('phone') }}
                                    </label>
                                    <input id="phone" type="text" name="phone" placeholder="+966 12 345 6789"
                                        class="w-full p-4 mt-2 text-sm font-bold text-white transition duration-200 ease-in-out border-2 border-solid outline-none h-14 rounded-2xl dark:bg-dark-700 bg-gray-200 dark:border-dark-800 border-gray-400  focus:border-primary-color-600 focus:outline-none" />
                                </div>
                                <div class="mt-6">
                                    <label for="message" class="ml-0.5 dark:text-gray-300 font-bold text-sm">
                                        {{ $t('message') }} *
                                    </label>
                                    <textarea id="message" type="text" name="message" placeholder="Message" rows="5"
                                        class="w-full p-4 mt-2 text-sm font-bold text-white transition duration-200 ease-in-out border-2 border-solid outline-none rounded-2xl dark:bg-dark-700 bg-gray-200 dark:border-dark-800 border-gray-400  focus:border-primary-color-600 focus:outline-none"
                                        required></textarea>
                                </div>
                                <div class="flex justify-start mt-6">
                                    <button type="submit"
                                        class="flex items-center justify-center w-auto px-8 py-4 text-base font-semibold leading-snug transition ease-in-out bg-white rounded-full h-14 duration-250 text-dark-900 hover:text-white focus:outline-none hover:bg-dark-900">
                                        {{ $t('send message') }}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <LetsContact data-aos="fade-up"/>
                <SectionTitle data-aos="fade-up" class="my-12" :msg="$t('our location')" />
                <iframe data-aos="fade-up"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3623.391891387577!2d46.775170200000005!3d24.747749100000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f0100728162e9%3A0x56a8815ef89d1d63!2z2LTYsdmD2Kkg2YHYsti52Kkg2YTZhNin2YbYqtin2Kwg2KfZhNmF2LHYptmKINmI2KfZhNmF2LPZhdmI2Lk!5e0!3m2!1sen!2s!4v1720335975947!5m2!1sen!2s"
                    class="w-full h-72 dark:border-white border-secondary-color border-0 rounded-3xl" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </section>

    </div>
</template>

<script>
import SectionTitle from '@/components/SectionTitle.vue';
import LetsContact from '@/components/LetsContact.vue';
export default {
    components: {
        SectionTitle,
        LetsContact,
    },
}

</script>
