<template>
    <div>
        <!-- <transition appear name="fade">
            <div>
                <h1
                    class="align mb-6 text-4xl font-bold tracking-tight text-gray-900 md:text-4xl lg:text-4xl dark:text-white">
                    <span
                        class="text-transparent bg-clip-text bg-gradient-to-r from-primary-color-600 to-secondary-color dark:from-primary-color-600 dark:to-white">
                        {{ $t('our projects') }}
                    </span>
                </h1>
            </div>
        </transition> -->
        <OurWork />
    </div>
</template>


<script>
import gsap from 'gsap';
import { ref } from 'vue';
import OurWork from '@/components/OurWork.vue'

export default {
    components:{
        OurWork,
    },
    setup() {
        
    
    },
};
</script>