<template>
    <div>
        <section data-aos="fade-up" class="max-w-screen-xl px-4 pt-10 pb-12 mx-auto md:pb-16 md:pt-12 sm:px-6 lg:px-8">
                <div class="w-full max-w-xl mx-auto text-center lg:max-w-3xl md:max-w-2xl">
                    <SectionTitle msg="Testimonials"/>
                    <h2 class="mt-6 text-3xl font-extrabold dark:text-white text-secondary-color sm:text-4xl md:text-5xl">
                        What they say about us
                    </h2>
                    <p class="mt-6 text-xl dark:text-dark-300 text-gray-500">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius
                        mod tempor incididunt ut labore et
                    </p>
                </div>
                <div
                    class="grid gap-4 mt-12 lg:mt-14 xl:grid-cols-3 xl:grid-rows-2 sm:gap-6 lg:gap-8 md:grid-cols-2 md:grid-rows-3">
                    <div class="md:row-span-2">
                        <div class="w-full h-full px-4 py-12 shadow-xl rounded-3xl dark:bg-dark-700 bg-primary-color-800  lg:py-16 lg:px-6">
                            <div class="max-w-md mx-auto text-center lg:max-w-full">
                                <h3 class="text-2xl font-bold text-white lg:text-4xl sm:text-3xl">
                                    Discover why so many companies trust us
                                </h3>
                                <p class="mt-3 text-lg text-dark-300 lg:mt-4">
                                    Lorem ipsum dolor, sit amet consectetur elit do eius mod
                                    tempor labore sit amet.
                                </p>
                            </div>
                            <div class="mt-8 text-center sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8 md:block">
                                <div class="flex flex-col">
                                    <p class="order-2 mt-2 text-lg font-medium leading-6 dark:text-dark-400 text-white">
                                        Happy clients
                                    </p>
                                    <p class="order-1 text-5xl font-bold leading-none text-white">
                                        69
                                    </p>
                                </div>
                                <div class="flex flex-col mt-10 sm:mt-0 md:mt-10">
                                    <p class="order-2 mt-2 text-lg font-medium leading-6 dark:text-dark-400 text-white">
                                        Projects completed
                                    </p>
                                    <p class="order-1 text-5xl font-bold leading-none text-white">
                                        420
                                    </p>
                                </div>
                                <div class="flex flex-col mt-10 sm:mt-0 md:mt-10">
                                    <p class="order-2 mt-2 text-lg font-medium leading-6 dark:text-dark-400 text-white">
                                        Years in business
                                    </p>
                                    <p class="order-1 text-5xl font-bold leading-none text-white">
                                        20
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="relative flex w-full px-4 py-12 overflow-hidden shadow-xl rounded-3xl dark:bg-dark-700 bg-primary-color-800  sm:px-6 lg:px-8">
                        <svg viewBox="0 0 24 24" class="absolute inset-0 w-32 h-32 dark:text-dark-800 text-primary-color-900 lg:w-40 lg:h-40">
                            <path fill="currentColor"
                                d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z">
                            </path>
                        </svg>
                        <blockquote class="z-10 mx-auto">
                            <p class="max-w-md mx-auto text-lg font-medium leading-9 text-center text-dark-300">
                                Nemo expedita voluptas culpa sapiente alias molestiae corrupti
                                in laborum sed rerum et corporis.
                            </p>
                            <footer class="mt-8">
                                <img class="object-cover object-center w-12 h-12 mx-auto border-2 border-opacity-75 border-solid rounded-full border-dark-800"
                                    loading="lazy" 
                                    src="../assets/images/tv_broadcast_or_hd_video_camera_on_crane.jpg" alt="" />
                                <div class="mt-3 text-center">
                                    <div class="text-base font-medium leading-6 text-white">
                                        Katy Perry
                                    </div>
                                    <div class="text-base font-medium leading-6 dark:text-dark-400 text-white">
                                        CEO, Spotify
                                    </div>
                                </div>
                            </footer>
                        </blockquote>
                    </div>
                    <div
                        class="relative flex w-full px-4 py-12 overflow-hidden shadow-xl rounded-3xl dark:bg-dark-700 bg-primary-color-800  sm:px-6 lg:px-8">
                        <svg viewBox="0 0 24 24" class="absolute inset-0 w-32 h-32 dark:text-dark-800 text-primary-color-900 lg:w-40 lg:h-40">
                            <path fill="currentColor"
                                d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z">
                            </path>
                        </svg>
                        <blockquote class="z-10 mx-auto">
                            <p class="max-w-md mx-auto text-lg font-medium leading-9 text-center text-dark-300">
                                Nemo expedita voluptas culpa sapiente alias molestiae corrupti
                                in laborum sed rerum et corporis.
                            </p>
                            <footer class="mt-8">
                                <img class="object-cover object-center w-12 h-12 mx-auto border-2 border-opacity-75 border-solid rounded-full border-dark-800"
                                    loading="lazy"
                                    src="../assets/images/tv_broadcast_or_hd_video_camera_on_crane.jpg" alt="" />
                                <div class="mt-3 text-center">
                                    <div class="text-base font-medium leading-6 text-white">
                                        Henry Krasner
                                    </div>
                                    <div class="text-base font-medium leading-6 dark:text-dark-400 text-white">
                                        CTO, Twitter
                                    </div>
                                </div>
                            </footer>
                        </blockquote>
                    </div>
                    <div
                        class="relative flex w-full px-4 py-12 overflow-hidden shadow-xl rounded-3xl dark:bg-dark-700 bg-primary-color-800  sm:px-6 lg:px-8">
                        <svg viewBox="0 0 24 24" class="absolute inset-0 w-32 h-32 dark:text-dark-800 text-primary-color-900 lg:w-40 lg:h-40">
                            <path fill="currentColor"
                                d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z">
                            </path>
                        </svg>
                        <blockquote class="z-10 mx-auto">
                            <p class="max-w-md mx-auto text-lg font-medium leading-9 text-center text-dark-300">
                                Nemo expedita voluptas culpa sapiente alias molestiae corrupti
                                in laborum sed rerum et corporis.
                            </p>
                            <footer class="mt-8">
                                <img class="object-cover object-center w-12 h-12 mx-auto border-2 border-opacity-75 border-solid rounded-full border-dark-800"
                                    loading="lazy"
                                    src="../assets/images/video_editing_timeline.jpg" alt="" />
                                <div class="mt-3 text-center">
                                    <div class="text-base font-medium leading-6 text-white">
                                        Bob Foster
                                    </div>
                                    <div class="text-base font-medium leading-6 dark:text-dark-400 text-white">
                                        CEO, Amazon
                                    </div>
                                </div>
                            </footer>
                        </blockquote>
                    </div>
                    <div
                        class="relative flex w-full px-4 py-12 overflow-hidden shadow-xl rounded-3xl dark:bg-dark-700 bg-primary-color-800  sm:px-6 lg:px-8">
                        <!-- Quotation mark -->
                        <svg viewBox="0 0 24 24" class="absolute inset-0 w-32 h-32 dark:text-dark-800 text-primary-color-900 lg:w-40 lg:h-40">
                            <path fill="currentColor"
                                d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z">
                            </path>
                        </svg>
                        <blockquote class="z-10 mx-auto">
                            <p class="max-w-md mx-auto text-lg font-medium leading-9 text-center text-dark-300">
                                Nemo expedita voluptas culpa sapiente alias molestiae corrupti
                                in laborum sed rerum et corporis.
                            </p>
                            <footer class="mt-8">
                                <img class="object-cover object-center w-12 h-12 mx-auto border-2 border-opacity-75 border-solid rounded-full border-dark-800"
                                    loading="lazy"
                                    src="../assets/images/tv_broadcast_or_hd_video_camera_on_crane.jpg" alt="" />
                                <div class="mt-3 text-center">
                                    <div class="text-base font-medium leading-6 text-white">
                                        Ema Roberts
                                    </div>
                                    <div class="text-base font-medium leading-6 dark:text-dark-400 text-white">
                                        Owner, Dropbox
                                    </div>
                                </div>
                            </footer>
                        </blockquote>
                    </div>
                </div>
            </section>
    </div>
</template>