<template>
    <div>
        <section
            class="px-4 pt-12 pb-10 md:pb-12 md:pt-16 bg-gray-200 rounded-2xl dark:bg-dark-800 sm:px-6 lg:px-8">
            <div class="w-full max-w-screen-xl mx-auto">
                <div class="w-full max-w-xl mx-auto text-center lg:max-w-3xl md:max-w-2xl">
                    <SectionTitle :msg="$t('our projects')" />
                    <h2 class="mt-6 text-3xl font-extrabold dark:text-white text-secondary-color sm:text-4xl md:text-5xl">
                        {{$t('Check out some of our recent & current work')}}
                    </h2>
                </div>
                <div class="mt-12 space-y-12 lg:mt-16 lg:space-y-56 lg:pb-24">
                    <transition-group appear @before-enter="beforeEnter" @enter="enter">
                        <div v-for="(project, index) in projects" :key="index" :data-index="index">
                            <WorkItem 
                                :title="project.title" 
                                :category="project.category.name" 
                                :image="project.image"
                                :url="project.url"
                                :description="project.description" 
                                :dir="index % 2 === 0 ? 'right' : 'left'"
                                :data-aos="index % 2 === 0 ? 'fade-right' : 'fade-left'"
                            />
                        </div>
                    </transition-group>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import SectionTitle from '@/components/SectionTitle.vue'
import WorkItem from '@/components/WorkItem.vue'
import gsap from 'gsap';
import axios from 'axios';
import { ref,onMounted } from 'vue';
export default {
    name: 'Partners',
    components: {
        SectionTitle,
        WorkItem,
    },
    setup() {

        const projects = ref([]);


        const beforeEnter = (el) => {
            el.style.transform = 'translateY(-60px)';
            el.style.opacity = 0;
        };

        const enter = (el, done) => {
            gsap.to(el, {
                opacity: 1,
                y: 0,
                duration: 0.8,
                delay: el.dataset.index * 0.1,
                onComplete: done,
            });
        };
        onMounted(async () => {
            try {
                const response = await axios.get('/works/');
                projects.value = response.data;
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        });
        return {
            enter,
            beforeEnter,
            projects,
        };
    },
}
</script>