<template>
    <div class="home">
        <main class="dark:bg-gray-900">
            <Hero class="mb-4"/>
            <!-- <Partners data-aos="fade-up"/> -->
            <WhatWeDo />
            <Production />
            <OurWork />
            <!-- <Testimonials /> -->
            <LetsContact />
        </main>
    </div>
</template>
<script>
import Services from '@/components/Services.vue';
import LetsContact from '@/components/LetsContact.vue';
import Hero from '@/components/Hero.vue'
import SectionTitle from '@/components/SectionTitle.vue'
import Partners from '@/components/Partners.vue'
import WhatWeDo from '@/components/WhatWeDo.vue'
import OurWork from '@/components/OurWork.vue'
import Production from '@/components/Production.vue'
import Testimonials from '@/components/Testimonials.vue'
import { ref, onMounted } from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default {
    name: 'HomeView',
    components: {
        Services,
        LetsContact,
        Hero,
        SectionTitle,
        Partners,
        OurWork,
        WhatWeDo,
        Production,
        Testimonials,
    },
    setup() {



        return {};
    },
}
</script>
