<template>
    <div class="relative max-w-lg mx-auto lg:max-w-none lg:mx-none"
>
        <div
            class="relative w-full overflow-hidden h-64 bg-center bg-cover shadow-xl lg:rounded-3xl rounded-t-3xl lg:pt-[46%] lg:w-[90%] lg:h-full"
            :class="dir === 'left' ? 'lg:me-auto' : 'lg:ms-auto'"
            >
            
            <img v-if="image" :src="image" :alt="title" loading="lazy"
                class="absolute inset-0 object-cover object-center w-full 
                h-full lg:rounded-3xl rounded-t-3xl
                hover:scale-110 overflow-hidden transition-transform duration-300" />
            <div v-else 
                class="absolute inset-0 object-cover object-center w-full 
                    h-full lg:rounded-3xl rounded-t-3xl
                    scale-125 overflow-hidden transition-transform duration-300"
                    >
                <iframe 
                    :src="modifiedUrl"
                    class="w-full h-full"
                    >
                </iframe>
            </div>
        </div>
        <div
            class="relative bottom-0 #FirstOne end-0 z-20 block w-full h-auto p-6 shadow-xl lg:rounded-3xl rounded-b-3xl sm:p-8 lg:p-12 dark:bg-dark-700 bg-primary-color-800  lg:h-full lg:absolute lg:-bottom-25 lg:w-1/3"
            :class="dir === 'left' ? 'end-0' : 'start-0'">
            <div class="h-full lg:flex lg:flex-col lg:justify-between">
                <div class="hidden lg:block">
                    <p
                        class="inline-flex items-center justify-center px-6 py-2 text-xs font-medium tracking-wide text-white rounded-r-full rounded-tl-full bg-gradient-to-r from-dark-800 to-dark-900">
                        {{ category }}
                    </p>
                </div>
                <div class="w-full">
                    <h3 class="text-3xl font-bold text-white lg:text-4xl">
                        {{ title }}
                    </h3>
                    <p class="mt-2 text-base lg:mt-4 text-white lg:text-lg">
                        {{ description }}
                    </p>
                </div>
                <div class="mt-8 xl:mt-4">
                    <a class="relative inline-flex items-center justify-center w-auto h-12 px-8 text-base font-medium leading-snug text-white md:h-14 group"
                        :href="url">
                        <span
                            class="absolute top-0 left-0 shadow-lg z-10 block w-full h-full transition-all ease-in-out rounded-full bg-gradient-to-r duration-250 from-primary-color-800 to-transparent md:w-14 md:group-hover:w-full"></span>
                        <span class="relative z-20">{{$t('Learn more')}}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        category: {
            type: String,
            required: true
        },
        image: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        url: {
            type: String,
            required: false
        },
        title: {
            type: String,
            required: true
        },
        dir: {
            type: String,
            required: true
        },
    },
    setup(props) {
        const isYoutubeUrl = (url) => {
            if(url){
                return url.includes('youtube.com') || url.includes('youtu.be');
            }
        };
        const isVimeoUrl = (url) => {
            if(url){
                return url.includes('vimeo.com');
        }
        };
        const getEmbedUrl = (url) => {
            if (isYoutubeUrl(url)) {
                const videoId = extractVideoId(url, 'youtube');
                return `https://www.youtube.com/embed/${videoId}`;
            } else if (isVimeoUrl(url)) {
                const videoId = extractVideoId(url, 'vimeo');
                return `https://player.vimeo.com/video/${videoId}`;
            } else {
                return url; // If it's not a supported URL, return the original URL
            }
        };

        const extractVideoId = (url, type) => {
        if (type === 'youtube') {
            const match = url.match(/[?&]v=([^&]+)/);
            return match ? match[1] : null;
        } else if (type === 'vimeo') {
            const match = url.match(/\/(\d+)$/);
            return match ? match[1] : null;
        }
    };

        // Modify the URL prop if it's a YouTube URL
        const modifiedUrl = getEmbedUrl(props.url);

        return {
            modifiedUrl
        };
    }
}
</script>