<template>
  <div :dir="$i18n.locale === 'AR' ? 'rtl' : 'ltr'" class="dark:bg-gray-900" >
    <header class="bg-dark-900">
    <nav class="bg-white border-gray-200 dark:bg-gray-900 fixed z-50 w-full top-0 start-0 shadow">
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto ">
      <router-link to="/" class="flex items-center space-x-3 rtl:space-x-reverse">
          <img v-if="isDarkMode" src="@/assets/logos/fazaa-logo-w-png.png" class="h-14" alt="Fazza Logo" />
          <img v-else src="@/assets/logos/fazaa-logo-r-png.png" class="h-14" alt="Fazza Logo" />
          <!-- <span class="hidden sm:block self-center text-2xl font-semibold whitespace-nowrap dark:text-white text-secondary-color">{{ $t("fazaa") }}</span> -->
      </router-link>
      <div class="flex items-center md:order-2 space-x-1 md:space-x-0 rtl:space-x-reverse">
        
          <button @click="changeLocale('AR')" type="button" 
          class="inline-flex items-center font-medium justify-center px-4 py-2 text-sm text-secondary-color dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white mx-1" :class="$i18n.locale === 'AR' ? 'bg-gray-100 dark:bg-gray-800' : ''">
            {{ $t("arabic") }}
          </button>
          <button @click="changeLocale('EN')" type="button" 
          class="inline-flex items-center font-medium justify-center px-4 py-2 text-sm text-secondary-color dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white mx-1" :class="$i18n.locale === 'EN' ? 'bg-gray-100 dark:bg-gray-800' : ''">
            {{ $t("english") }}
          </button>
          <button @click="toggleDarkMode()" type="button" 
          class="inline-flex items-center font-medium justify-center px-4 py-2 text-sm text-secondary-color dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white mx-1">
          <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-brightness-high-fill" viewBox="0 0 16 16">
              <path v-if="isDarkMode" d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708"/>
              <path v-else d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278"/>
          </svg>
          </button>
          <button data-collapse-toggle="navbar-language" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-gray-600" aria-controls="navbar-language" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
            </svg>
        </button>
      </div>
      <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-language">
        <ul class="flex flex-col font-medium p-4 md:p-0 mt-4 
          border border-gray-100 rounded-lg bg-gray-50
          md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 
          md:border-0 md:bg-white dark:bg-gray-800
          md:dark:bg-gray-900 dark:border-gray-700">
          <li>
            <router-link to="/" :class="{ 'text-primary-color-600': $route.path === '/' }"
            class="
              block py-2 px-3 md:p-0 text-secondary-color rounded 
              hover:text-primary-color-600
              md:hover:text-primary-color-600
              dark:text-white md:dark:hover:text-primary-color-600 dark:hover:bg-gray-800
              dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700
              " aria-current="page" 
              >{{ $t('home') }}</router-link>
          </li>
          <li>
            <router-link to="/about" 
            class="
              block py-2 px-3 md:p-0 text-secondary-color rounded
              hover:text-primary-color-600
              dark:text-white md:dark:hover:text-primary-color-600 dark:hover:bg-gray-800
              dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700
              " aria-current="page">{{ $t('about') }}</router-link>
          </li>
          <li>
            <router-link to="/projects" class="
              block py-2 px-3 md:p-0 text-secondary-color rounded
              hover:text-primary-color-600
              md:hover:text-primary-color-600
              dark:text-white md:dark:hover:text-primary-color-600 dark:hover:bg-gray-800
              dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700
            " aria-current="page">{{ $t('our projects') }}</router-link>
          </li>
          <li>
            <router-link to="/joinus" class="
              block py-2 px-3 md:p-0 text-secondary-color rounded
              hover:text-primary-color-600
              md:hover:text-primary-color-600
              dark:text-white md:dark:hover:text-primary-color-600 dark:hover:bg-gray-800
              dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700
            " aria-current="page">{{ $t('Join Us') }}</router-link>
          </li>
          <li>
            <router-link to="/contact" class="
              block py-2 px-3 md:p-0 text-secondary-color rounded
              hover:text-primary-color-600
              md:hover:text-primary-color-600
              dark:text-white md:dark:hover:text-primary-color-600 dark:hover:bg-gray-800
              dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700
            " aria-current="page">{{ $t('contact us') }}</router-link>
          </li>
        </ul>
      </div>
      </div>
    </nav>
  </header>



    <div class="dark:bg-gray-900 mt-24 px-4 pt-6 pb-12 mx-auto max-w-screen-2xl md:pb-16 sm:px-6 lg:px-8">
      <router-view v-slot="{ Component }">
        <transition name="route" mode="out-in">
          <component :is="Component" class="overflow-hidden"></component>
        </transition>
      </router-view>
    </div>
    
    <Footer />


  </div>
</template>
<script>
import { useLocaleStore } from '@/store';
import Footer from '@/components/Footer.vue';
import { ref , onMounted } from 'vue';
import Cookies from 'js-cookie';
import AOS from 'aos'
import { useI18n } from 'vue-i18n';
import { i18n } from '@/main'; 
export default {
  components: {
    Footer,
  },
  setup() {
    const { t } = useI18n({ i18n });
    AOS.init();
    const store = useLocaleStore();
    const isDarkMode = ref(true) 
    const changeLocale = (lang) => {
      store.changeLang(lang);
      // i18n.global.locale = lang;
      window.location.reload();
    }
    onMounted(() => {
      const storedDarkModeValue = Cookies.get("isDarkMode");      
      if (storedDarkModeValue === "true" || storedDarkModeValue === undefined) {
          isDarkMode.value = true;
          document.body.classList.add('dark');
          document.body.classList.add('dark-body');
          Cookies.set("isDarkMode", true);
      } else {
          isDarkMode.value = false;
          document.body.classList.remove('dark');
          document.body.classList.remove('dark-body');
          Cookies.set("isDarkMode", false);
      }

    })

    const toggleDarkMode = () => {
      isDarkMode.value = !isDarkMode.value;
      document.body.classList.toggle('dark', isDarkMode.value);
      document.body.classList.toggle('dark-body', isDarkMode.value);
      const newDarkModeValue = isDarkMode.value;
      Cookies.set("isDarkMode", newDarkModeValue);
    }

    return {
      store, 
      changeLocale, 
      toggleDarkMode,
      isDarkMode,
      t,
    };
  },
};
</script>

<style>

.route-enter-from{
  opacity: 0;
  transform: translateX(100px);
}
.route-enter-active{
  transition: all 0.5s ease-out;
}
.route-leave-to{
  opacity: 0;
  transform: translateX(-100px);
}
.route-leave-active{
  transform: translateX(-100px);
}

</style>