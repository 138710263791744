<template>
    <section>
        <div class="relative shadow-xl rounded-3xl sm:overflow-hidden dark:bg-gray-800 bg-gray-200">
            <svg class="absolute inset-y-0 z-20 w-1/4 h-full right-1/4 dark:text-gray-800 text-gray-200" preserveaspectratio="none"
                viewbox="0 0 100 100" fill="currentcolor">
                <polygon points="0,0 100,0 0,1000" />
            </svg>
            <div class="absolute inset-y-0 z-10 w-1/2 h-full left-1/2 dark:bg-dark-800 bg-gray-300 rounded-r-3xl"></div>
            <div
                class="relative max-w-screen-xl px-4 py-16 mx-auto lg:grid lg:grid-cols-2 lg:auto-rows-max lg:gap-x-8 xl:gap-x-12 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <div class="flex flex-col items-center justify-center lg:items-start">
                    <p
                        data-aos ="zoom-in"
                        class="z-30 inline-flex items-center justify-center px-6 py-2 text-sm font-medium tracking-wide text-white rounded-r-full rounded-tl-full bg-gradient-to-r from-primary-color-600 to-primary-color-800">
                        {{ $t('welcome') }}
                    </p>
                    <div class="z-30 w-full max-w-lg mx-auto mt-6 text-center lg:text-left lg:max-w-none md:max-w-2xl" >
                        <h1 
                        data-aos="fade-right"
                        class="text-4xl font-extrabold dark:text-white text-secondary-color sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl text-start leading-10">
                            {{ $t('Lets make your Idea Real') }}
                        </h1>
                        <p 
                        data-aos="fade-right"
                        class="mt-6 text-xl dark:text-gray-200 text-gray-700 text-start">
                            {{ $t('hero_description') }}
                        </p>
                    </div>
                    <div class="z-30 mt-10 sm:flex sm:justify-center lg:justify-start">
                        <router-link
                            data-aos="zoom-out"
                            class="flex items-center justify-center duration-250 mx-2 w-auto px-8 py-4 text-base font-semibold 
                            leading-snug transition-all ease-in-out bg-white rounded-full h-14 duration-250 text-dark-900
                             hover:text-white focus:outline-none hover:bg-primary-color-800"
                            to="/contact">
                            {{ $t('Lets get started') }}
                        </router-link>
                        <router-link class="relative flex items-center justify-center w-auto px-8 mt-5 ml-0 text-base font-bold leading-snug text-gray-100 dark:text-white h-14 sm:ml-4 sm:mt-0 group"
                            to="/about">
                            <span
                                data-aos="zoom-out"
                                class="absolute top-0 rtl:right-0 left-0 z-10 block w-full h-full transition-all ease-in-out rounded-full bg-gradient-to-r duration-300 from-primary-color-600 to-primary-color-800 md:w-14 md:group-hover:w-full"></span>
                            <span class="relative z-20 duration-250 transition ease-in-out">{{ $t('Learn more') }}</span>
                        </router-link>
                    </div>
                </div>
                <div class="flex items-center justify-center max-w-xl mx-auto mt-12 sm:mt-16 lg:mt-0 lg:max-w-none">
                    <img
                        data-aos="zoom-in" loading="lazy"
                        src="../assets/images/shutterstock_1.jpg"
                        class="z-30 object-cover w-auto h-full shadow-md rounded-3xl" />
                </div>
            </div>
        </div>
    </section>
</template>