<template>
    <div>
        <section class="px-4 py-10 md:py-12 bg-gray-200 rounded-2xl dark:bg-dark-800 sm:px-6 lg:px-8">
                <div class="max-w-xl mx-auto text-center md:max-w-2xl lg:text-left lg:max-w-screen-xl">
                    <SectionTitle :msg="$t('Whatwedo')"/>
                    <div class="grid w-full gap-6 mt-6 lg:grid-cols-5">
                        <div class="lg:col-span-3">
                            <h2 data-aos="fade-right" class="text-3xl font-extrabold text-secondary-color dark:text-white sm:text-4xl md:text-5xl">
                                {{$t("We create digital products that help you get ahead")}}
                            </h2>
                        </div>
                        <div class="lg:col-span-2" data-aos="fade-left">
                            <p class="text-xl dark:text-dark-300 text-gray-500">
                                {{$t("Pre-Production")}}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    class="grid justify-center w-full gap-12 mx-auto mt-12 lg:grid-cols-2 lg:gap-8 lg:mt-16 lg:max-w-screen-xl xl:max-w-screen-xl lg:justify-start">
                    <div>
                        <div v-for="(service,index) in services" :key="index" class="w-full mt-8 lg:mt-5 xl:mt-8 2xl:mt-10 xl:pr-18 2xl:pr-28">
                            <div class="max-w-lg mx-auto lg:mr-auto xl:mx-auto" data-aos="zoom-in">
                                <div class="relative flex w-full px-8 py-6 shadow-xl rounded-3xl dark:bg-dark-700 bg-primary-color-800  sm:px-10">
                                    <div class="sm:flex sm:text-start">
                                        <div class="w-full sm:w-1/5">
                                            <div
                                                class="flex items-center justify-center w-12 h-12 mx-auto bg-gradient-to-r rounded-2xl text-white sm:mx-0 from-dark-800 to-dark-900">
                                                <svg class="ml-auto mt-auto" xmlns="http://www.w3.org/2000/svg" width="35"
                                                    height="35" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor"
                                                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path :d="service.icon"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="w-full mt-3 sm:mt-0">
                                            <h5 class="text-lg font-semibold text-white">
                                                {{ service.title }}
                                            </h5>
                                            <p class="mt-1 text-base text-white">
                                                {{ service.description }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="flex items-center justify-center max-w-screen-md lg:max-w-full">
                        <div class="grid h-full grid-flow-col grid-rows-2 gap-4">
                            <div class="row-span-2 shadow-xl rounded-3xl">
                                <img loading="lazy" src="../assets/images/video_camera_set_up_for_recording_movie.jpg"
                                data-aos="zoom-out"
                                    class="object-cover object-right w-full h-full rounded-3xl" />
                            </div>
                            <div class="shadow-xl rounded-3xl">
                                <img loading="lazy" src="../assets/images/tv_broadcast_or_hd_video_camera_on_crane.jpg"
                                data-aos="zoom-out"
                                    class="object-cover object-center w-full h-full rounded-3xl" />
                            </div>
                            <div class="shadow-xl rounded-3xl">
                                <img loading="lazy" src="../assets/images/video_editing_timeline.jpg"
                                data-aos="zoom-out"
                                    class="object-cover object-center w-full h-full rounded-3xl" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
</template>
<script>
import SectionTitle from '@/components/SectionTitle.vue'
import { ref } from 'vue';
import { useI18n } from "vue-i18n";

export default {
    name: 'Partners',
    components: {
        SectionTitle,
    },
    setup() {
        const { t } = useI18n();

        const services = ref([
            {
                title: t("ConceptDevelopment"), 
                description:t("ConceptDevelopment_desc"), 
                icon: "M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z",
            },
            { 
                title: t("ScriptwritingAndStoryBoarding"), 
                description: t("ScriptwritingAndStoryBoarding_desc"), 
                icon: "m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z",
            },
            { 
                title: t("LocationScoutingAndPermitting"), 
                description: t("LocationScoutingAndPermitting_desc"),
                icon: "M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.3 1.3 0 0 0-.37.265.3.3 0 0 0-.057.09V14l.002.008.016.033a.6.6 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.6.6 0 0 0 .146-.15l.015-.033L12 14v-.004a.3.3 0 0 0-.057-.09 1.3 1.3 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465s-2.462-.172-3.34-.465c-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411",
            },
            { 
                title: t("CastingAndTalentAcquisition"), 
                description: t("CastingAndTalentAcquisition_desc"), 
                icon: "M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5 \
                        M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3",
            },
            { 
                title: t("BudgetingAndScheduling"), 
                description: t("BudgetingAndScheduling_desc"), 
                icon: "M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a2 2 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1",
            },
            { 
                title: t("CrewAndEquipmentSourcing"), 
                description: t("CrewAndEquipmentSourcing_desc"), 
                icon: "M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1z",
            },
        ]);


        return {
            services,
        };
    },
}
</script>